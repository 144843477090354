<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('affiliateSupport')" goto="/referral" />
    </div>
    <div class="faq">
      <PlayJackpotFilter :tabs="tabs" @updateActive="handleActiveChange" />
      <div v-if="activeTab === 0">
        <Rule />
      </div>
      <div v-if="activeTab === 1">
        <Faq />
      </div>
    </div>
  </div>
</template>

<script>
import BackComponent from "../../../components/ui/BackComponent.vue";
import PlayJackpotFilter from "../../play-jackpot/components/PlayJackpotFilter.vue";
import Faq from "./faq/Faq.vue";
import Rule from "./rules/Rule.vue";

export default {
  name: "AffiliateSupport",
  data: function () {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Rules",
          name: "rules",
        },
        {
          label: "FAQ",
          name: "faq",
        },
      ],
    };
  },
  components: {
    BackComponent,
    Faq,
    PlayJackpotFilter,
    Rule,
  },
  methods: {
    handleActiveChange(activeValue) {
      this.activeTab = activeValue;
    },
  },
};
</script>

<style src="./index.css" scoped></style>
