<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('referrals')" goto="/my-account" />
    </div>
    <div class="referral_summary">
      <div class="affiliate_banner">
        <div>
          <div class="text">
            <p>
              {{ $t("referYour") }} <b> {{ fiatCurrency }} 100</b>
            </p>
          </div>
          <img
            class="img"
            src="/img/referral_thropy.svg"
            alt="referral_thropy"
          />
        </div>
      </div>
      <div class="commission">
        <div class="stat_heading">
          <div class="crown">
            <AgentLevel />
          </div>
          <div class="info_scroll">
            <div class="scroll-content" ref="scrollContent">
              <p v-for="(item, index) in items" :key="index">
                {{ item.text }} {{ $t("justWon") }}
                <span>{{ item.amount }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="stat_details_container">
          <StatDetail :label="$t('currentBalance')" :digit="balance" />
          <StatDetail :label="$t('totalPaid')" :digit="totalPaid" />
        </div>
        <div class="stat_button">
          <ChopbetButton
            variant="primary"
            :size="'small'"
            :loading="rewardLoading"
            @click="claimReward"
            :disabled="balance < 100"
            >{{ $t("claimReward") }}</ChopbetButton
          >
          <ChopbetButton
            @click="handlePage('/commission')"
            variant="outline"
            :size="'small'"
            >{{ $t("commissionHistory") }}</ChopbetButton
          >
        </div>
      </div>
      <div class="referral_code">
        <div class="left">
          <span>{{ $t("referralCode") }}</span>
          <div v-if="referralCodeLoading" class="skeleton-item"></div>
          <p v-else>{{ code }}</p>
        </div>
        <div class="copy_code" @click="copyCode">
          <p>{{ $t("copyCode") }}</p>
        </div>
      </div>
      <div class="referral_code">
        <div class="left">
          <span>{{ $t("referralLink") }}</span>
          <p>{{ shareLink }}</p>
        </div>
        <div class="copy_code" @click="handleShare">
          <p>{{ $t("shareLink") }}</p>
        </div>
      </div>
      <div class="commission_breakdown">
        <div class="commission_breakdown" v-if="codeSummaryLoading">
          <div
            class="breakdown"
            v-for="(c, index) in [1, 2, 3, 4]"
            :key="index"
          >
            <div class="skeleton-item"></div>
            <div class="skeleton-item"></div>
          </div>
        </div>
        <div
          class="breakdown"
          v-for="(c, index) in commissionSummary"
          :key="index"
          v-else
        >
          <span>{{
            c.type === 1
              ? $t("signups")
              : c.type === 2
              ? $t("deposit")
              : c.type === 3
              ? $t("sport")
              : c.type === 4
              ? $t("casino")
              : c.type === 5
              ? $t("jackpot")
              : $t("signups")
          }}</span>
          <p>
            {{ c.total_commission }} <span>{{ fiatCurrency }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="rules">
      <p @click="handlePage('/affiliate-support')">
        {{ $t("readRules") }}
      </p>
    </div>
    <div class="qr_code_wrapper">
      <div class="qr_code" @click="openSlideUp()">
        <QrCode />
      </div>
    </div>

    <Modal :isOpen="isSlideUpOpen" @onClose="closeSlideUp" maxWidth="600px">
      <div class="scan_modal">
        <div class="close">
          <span @click="closeSlideUp()"><CancelIcon /></span>
        </div>
        <div class="scan_code">
          <div class="scan_description">
            <p class="text">{{ $t("scanHeading") }}</p>
            <p class="des">
              {{ $t("scanDescription") }}
            </p>
          </div>
          <div class="code">
            <img :src="qrCode" alt="qr code" />
          </div>
        </div>
      </div>
    </Modal>

    <!-- <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <div class="scan_modal">
        <div class="close">
          <span @click="closeSlideUp()"><CancelIcon /></span>
        </div>
        <div class="scan_code">
          <div class="scan_description">
            <p class="text">{{ $t("scanHeading") }}</p>
            <p class="des">
              {{ $t("scanDescription") }}
            </p>
          </div>
          <div class="code">
            <img :src="qrCode" alt="qr code" />
          </div>
        </div>
      </div>
    </ChopbetSlideUp> -->
  </div>
</template>

<script>
import Modal from "../../components/ui/Modal.vue";
import BackComponent from "../../components/ui/BackComponent.vue";
import StatDetail from "./StatDetail.vue";
import QrCode from "../../components/icons/QrCode.vue";
// import Modal from "../../components/ui/Modal.vue";
// import ChopbetSlideUp from "../../components/ui/ChopbetSlideUp.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import AgentLevel from "@/components/icons/AgentLevel.vue";
import CancelIcon from "@/components/icons/CancelIcon.vue";
import {
  fetchBalance,
  fetchCommission,
  fetchReferralCode,
  initiateWithdrawal,
} from "../../actions/referral";

export default {
  name: "MyReferral",
  components: {
    ChopbetButton,
    AgentLevel,
    Modal,
    // ChopbetSlideUp,
    // Informations,
    BackComponent,
    // CopyIcon,
    StatDetail,
    // Payments,
    // Facebook,
    // LinkIcon,
    // Whatsapp,
    // Telegram,
    QrCode,
    CancelIcon,
    // CopyIcon,
  },
  data() {
    return {
      items: [
        { text: "2250****1810", amount: "400.91 CFA" },
        { text: "2250****9920", amount: "240.91 CFA" },
        { text: "2250****1640", amount: "500.91 CFA" },
        { text: "2250****5310", amount: "800.91 CFA" },
      ],
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      code: "",
      qrCode: "",
      isSlideUpOpen: false,
      activeCategory: "summary",
      currency: process.env.VUE_APP_CURRENCY,
      lang: this.$i18n.locale,
      isShareUpOpen: false,
      shareLink: "",
      balance: 0,
      totalPaid: 0,
      commissionSummary: [],
      rewardLoading: false,
      referralCodeLoading: true,
      codeSummaryLoading: true,
      loading: true,
    };
  },
  methods: {
    initCarousel() {
      const scrollContent = this.$refs.scrollContent;

      // Duplicate content for seamless scrolling
      scrollContent.innerHTML += scrollContent.innerHTML;

      // Start animation
      let scrollAmount = 0;
      const scrollSpeed = 1; // Adjust speed as needed

      const scroll = () => {
        scrollAmount += scrollSpeed;
        if (scrollAmount >= scrollContent.scrollWidth / 2) {
          scrollAmount = 0; // Reset to create infinite loop
        }
        scrollContent.style.transform = `translateX(-${scrollAmount}px)`;
        requestAnimationFrame(scroll);
      };

      scroll();
    },
    setCategory(category) {
      this.activeCategory = category;
    },
    closeSlideUp() {
      console.log("first");
      this.isSlideUpOpen = false;
    },

    openSlideUp() {
      this.isSlideUpOpen = true;
    },
    handlePage(link) {
      this.$router.push(link);
    },
    async claimReward() {
      this.rewardLoading = true;
      try {
        const apiKey = this.getAuth();
        const params = {
          apiKey,
          amount: this.balance >= 400 ? 400 : this.balance,
        };
        const data = await initiateWithdrawal(params);
        this.setSuccess(data.message);
        this.getBalance();
      } catch (error) {
        this.loadingGames = false;
        if ([401, 428].includes(error?.response?.status)) {
          this.setError(this.$t("sessionExpired"));
          this.logout();
          return;
        }

        this.setError(
          error?.response?.data?.error_response || "An error occurred"
        );
      } finally {
        this.rewardLoading = false;
      }
    },
    async getReferral() {
      this.referralCodeLoading = true;
      try {
        const apiKey = this.getAuth();
        const params = {
          apiKey,
        };
        const { data } = await fetchReferralCode(params);
        this.code = data.code;
        this.qrCode = data.qr_code_url;
      } catch (error) {
        this.loadingGames = false;
        if ([401, 400, 428].includes(error?.response?.status)) {
          this.setError(this.$t("sessionExpired"));
          this.logout();
          return;
        }
        this.setError(
          error?.response?.data?.error_response || "An error occurred"
        );
      } finally {
        this.referralCodeLoading = false;
      }
    },
    async getBalance() {
      // this.balance = data.balance;
      // this.totalPaid = data.totalEarned;
      try {
        const apiKey = this.getAuth();
        const params = {
          apiKey,
        };
        const { data } = await fetchBalance(params);
        this.balance = data.balance;
        this.totalPaid = data.total_earned;
        // this.code = data.code;
        // this.qrCode = data.qrCode;
      } catch (error) {
        this.loadingGames = false;
        if ([401, 400, 428].includes(error?.response?.status)) {
          this.setError(this.$t("sessionExpired"));
          this.logout();
          return;
        }
        this.setError(
          error?.response?.data?.error_response || "An error occurred"
        );
      } finally {
        this.is_busy = false;
        this.loadingGames = false;
      }
    },
    async getCommissionSummary() {
      this.codeSummaryLoading = true;
      try {
        const apiKey = this.getAuth();
        const params = {
          apiKey,
        };
        const { data } = await fetchCommission(params);
        this.commissionSummary = data;
      } catch (error) {
        this.loadingGames = false;
        if ([401, 400, 428].includes(error?.response?.status)) {
          this.setError(this.$t("sessionExpired"));
          this.logout();
          return;
        }
        this.setError(
          error?.response?.data?.error_response || "An error occurred"
        );
      } finally {
        this.codeSummaryLoading = false;
      }
    },
    async copyCode() {
      try {
        await navigator.clipboard.writeText(this.code);
        this.setSuccess("Text copied to clipboard!");
      } catch (err) {
        this.setError("Failed to copy text!");
      }
    },
    shareOnFacebook() {
      const code = this.code;
      this.closeSlideUp();
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.origin}/bet-slip/${code}`;
      window.open(facebookUrl, "_blank");
    },
    shareOnTelegram() {
      const code = this.code;
      this.closeSlideUp();
      const telegramWebUrl = `https://t.me/share/url?url=${encodeURIComponent(
        window.location.origin + "/bet-slip/" + code
      )}`;
      window.open(telegramWebUrl, "_blank");
    },
    shareOnWhatsApp() {
      const code = this.code;
      this.closeSlideUp();
      const whatsappUrl = `https://api.whatsapp.com/send?text=${window.location.origin}/bet-slip/${code}`;
      window.open(whatsappUrl, "_blank");
    },
    async copyShare() {
      try {
        const code = this.code;
        const fullUrl = `${window.location.origin}/${code}`;
        await navigator.clipboard.writeText(fullUrl);
        this.shareLink = fullUrl;
        this.setSuccess("Share link copied to clipboard!");
        this.closeSlideUp();
      } catch (err) {
        this.closeSlideUp();
        this.setError("Failed to copy text!");
      }
    },
    async handleShare() {
      if (navigator.share) {
        try {
          const code = this.code;
          const fullUrl = `${window.location.origin}/join?referral_code=${code}`;
          await navigator.clipboard.writeText(fullUrl);
          await navigator.share({
            title: this.$t("referralLink"),
            text: this.$t("shareLink"),
            url: fullUrl,
          });
        } catch (error) {
          console.error("Error sharing link:", error);
        }
      } else {
        alert(this.$t("shareNotSupported"));
      }
    },
    closeShareUp() {
      this.isShareUpOpen = false;
    },
  },
  mounted() {
    this.initCarousel();
    this.getReferral();
    this.getBalance();
    this.getCommissionSummary();
    this.shareLink = `${window.location.origin}/join?referral_code=${this.code}`;
  },
};
</script>

<style src="./index.css" scoped></style>
