<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('commissionHistory')" goto="/referral" />
    </div>
    <section class="page_container bets-menu">
      <div
        v-for="tab in tabs"
        :key="tab.name"
        class="tablinks"
        :class="isActive(tab.label)"
        @click="setActive(tab.label)"
      >
        {{ tab.label }}
      </div>
    </section>
    <section class="profile_links">
      <div class="filter">
        <button @click="handleAction('by-type')">
          <span> {{ selectedType }}</span>
          <span><CaretDown /></span>
        </button>
        <button class="filter-btn-2" @click="handleAction('by-time')">
          <div>
            <span><Calendar /></span>
            <span>{{ selectedTime }}</span>
          </div>
          <span><CaretDown /></span>
        </button>
      </div>
      <div class="load page_container" v-if="loading">
        <TransactionLoader :count="10" />
      </div>
      <div
        v-if="
          (!loading && !transaction) || (!loading && transaction.length < 1)
        "
        class="no_data"
      >
        <NoData
          :title="activeTab === 1 ? $t('noCommission') : $t('noTransaction')"
          :description="$t('noDataForFilter')"
        />
      </div>
      <div v-if="!loading && transaction && transaction.length > 0">
        <div
          v-for="(item, index) in transaction"
          :key="index"
          class="transaction_wrap"
        >
          <div>
            <div class="box_one">
              <span v-if="item.type === 1">
                <CreditIcon />
              </span>
              <span v-else>
                <DebitIcon />
              </span>
              <div class="description">
                <h3>{{ item.description }}</h3>
                <p>
                  {{ item.created }}
                </p>
              </div>
            </div>
            <div class="box_two">
              <h3>
                {{ item.type === 1 ? "+" : "-" }}
                {{ fiatCurrency }}
                {{ item.amount }}
              </h3>
              <p>{{ fiatCurrency }} {{ item.balance }}</p>
            </div>
          </div>
        </div>
      </div>
      <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
        <div class="learn_more">
          <div v-if="actionType === 'by-type'" class="filter_contents">
            <p>
              <span>{{ $t("filterByType") }}</span>
              <span @click="closeSlideUp"><CancelIcon /></span>
            </p>
            <div
              v-for="item in transactionType"
              :key="item"
              class="item"
              @click="handleSelectedType(item)"
            >
              <span
                ><span>{{ item.name }}</span>
                <span v-if="selectedType === item.name">
                  <TickCircle />
                </span>
                <span v-else> </span>
              </span>
            </div>
          </div>
          <div v-if="actionType === 'by-time'" class="filter_contents">
            <p>
              <span>{{ $t("filterByDate") }}</span>
              <span @click="closeSlideUp"><CancelIcon /></span>
            </p>
            <div v-if="timeCustom" class="custom-time">
              <div>
                <div class="start-date">
                  <label for="">{{ $t("from") }}</label>
                  <input
                    type="date"
                    v-model="selectedStartDate"
                    @change="handleDateChange(selectedStartDate, 'start')"
                    class="date-picker"
                    placeholder="Date"
                  />
                </div>
                <div class="start-date">
                  <label for="">{{ $t("to") }}</label>
                  <input
                    type="date"
                    v-model="selectedEndDate"
                    @change="handleDateChange(selectedEndDate, 'end')"
                    class="date-picker"
                    placeholder="Date"
                  />
                </div>
              </div>
              <ChopbetButton @click="handleSubmitDate" variant="primary">{{
                $t("done")
              }}</ChopbetButton>
            </div>
            <div
              v-else
              v-for="item in timeFilter"
              :key="item.id"
              class="item"
              @click="handleSelectedDate(item)"
            >
              <span
                ><span>{{ item.name }}</span>
                <span v-if="selectedTime === item.name"> <TickCircle /> </span
              ></span>
            </div>
          </div>
        </div>
      </ChopbetSlideUp>
    </section>
    <div v-if="transaction && transaction.length > 0">
      <scroll-loader
        v-show="transaction.length > 0"
        :loader-method="loadOnScroll"
        :loader-disable="is_busy || page >= last_page"
      >
        <div></div>
      </scroll-loader>
    </div>
  </div>
</template>

<script>
import CreditIcon from "../../../components/icons/CreditIcon.vue";
import DebitIcon from "../../../components/icons/DebitIcon.vue";
import NoData from "../../../components/ui/NoData.vue";
import ScrollLoader from "vue-scroll-loader";
import Vue from "vue";
import TransactionLoader from "../../transactions/TransactionLoader.vue";
import BackComponent from "../../../components/ui/BackComponent.vue";
import { fetchTransaction } from "../../../actions/referral";
import CaretDown from "../../../components/icons/CaretDown.vue";
// import Calendar from "../../../components/icons/Calendar.vue";
import ChopbetSlideUp from "../../../components/ui/ChopbetSlideUp.vue";
import TickCircle from "../../../components/icons/TickCircle.vue";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";

Vue.use(ScrollLoader);

export default {
  name: "Commission",
  data: function () {
    const today = new Date().toISOString().substr(0, 10);
    return {
      isSlideUpOpen: false,
      tabs: [
        {
          label: this.$t("all"),
          name: "all",
        },
        {
          label: "Commision",
          name: "commision",
        },
        {
          label: "Withdraws",
          name: "withdraws",
        },
      ],
      activeTab: 0,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      myProfile: this.getProfile(),
      loading: true,
      page: 1,
      per_page: 10,
      last_page: 1,
      timeCustom: false,
      start: "",
      end: "",
      sortDir: "DESC",
      type: "",
      currency: process.env.VUE_APP_CURRENCY,
      is_busy: false,
      transaction: [],
      hasMore: true,
      actionType: "by-time",
      commission_type: 0,
      active_menu: this.$t("all"),
      selectedValue: this.$t("showAll"),
      selectedDate: this.$t("showAll"),
      selectedType: this.$t("allTransactions"),
      selectedTime: this.$t("showAll"),
      selectedStartDate: today,
      selectedEndDate: today,
      timeFilter: [
        { id: 1, name: this.$t("showAll"), value: "Show All" },
        { id: 2, name: this.$t("today"), value: "Today" },
        { id: 3, name: this.$t("thisWeek"), value: "This Week" },
        { id: 4, name: this.$t("thisMonth"), value: "This Month" },
        { id: 5, name: this.$t("customDate"), value: "Custom Date" },
      ],
      transactionType: [
        {
          id: 1,
          name: this.$t("allTransactions"),
          value: "All transactions",
          commission_type: 0,
        },
        {
          id: 2,
          name: this.$t("registration"),
          value: "Registration",
          commission_type: 1,
        },
        {
          id: 3,
          name: this.$t("deposit"),
          value: "Deposit",
          commission_type: 2,
        },
        { id: 4, name: this.$t("bets"), value: "Bets", commission_type: 3 },
        {
          id: 5,
          name: this.$t("casinoBet"),
          value: "Casino Bet",
          commission_type: 4,
        },
        {
          id: 6,
          name: this.$t("JackpotBet"),
          value: "JackpotBet",
          commission_type: 5,
        },
      ],
    };
  },
  components: {
    ChopbetButton,
    TickCircle,
    CaretDown,
    BackComponent,
    TransactionLoader,
    CreditIcon,
    DebitIcon,
    ChopbetSlideUp,
    NoData,
  },
  methods: {
    closeSlideUp() {
      this.isSlideUpOpen = false;
      this.timeCustom = false;
    },
    handleAction(type) {
      this.actionType = type;
      this.isSlideUpOpen = true;
    },
    loadOnScroll: function () {
      var vm = this;
      if (vm.transaction) {
        vm.myTransactions();
      }
    },
    isActive: function (menu) {
      return this.active_menu === menu ? "active" : "";
    },
    setActive: function (menu) {
      this.active_menu = menu;
      this.start = "";
      this.end = "";
      this.activeTab =
        menu === this.$t("all") ? 0 : menu === "Commision" ? 1 : 2;
    },
    handleSubmitDate() {
      if (this.selectedStartDate && this.selectedEndDate) {
        this.start = this.selectedStartDate;
        this.end = this.selectedEndDate;
        this.closeSlideUp();
        this.selectedTime = this.$t("customDate");
        this.page = 1;
      }
    },
    async myTransactions() {
      const vm = this;
      var m = this.getProfile();
      var p = m.a;
      const {
        is_busy,
        page,
        per_page,
        last_page,
        start,
        end,
        sortDir,
        commission_type,
      } = this;

      if (is_busy) return;

      if (page === 1) {
        this.loading = true;
      }

      if (parseInt(page) > parseInt(last_page)) {
        this.is_busy = false;
        return;
      }

      this.is_busy = true;

      if (!p) {
        this.$router.push({ name: "login", params: {} });
        return;
      }

      try {
        let payload = {
          apiKey: this.getAuth(),
          page: page,
          per_page: per_page,
          start: start,
          end: end,
          sortDir: sortDir,
          commission_type: commission_type,
        };

        if (this.activeTab === 1) {
          payload.type = 1;
        }

        if (this.activeTab === 2) {
          payload.type = 2;
        }

        let transactions = await fetchTransaction(payload);

        if (transactions?.data.length === 0) {
          vm.transaction = null;
          this.is_busy = false;
          return;
        }

        this.last_page = transactions.last_page;

        if (parseInt(page) === 1) {
          vm.transaction = transactions.data;
        } else {
          vm.transaction.push(...transactions.data);
        }
        vm.page = parseInt(page) + 1;
      } catch (error) {
        this.loading = false;
        this.handleFetchError(error);
      } finally {
        this.is_busy = false;
        this.loading = false;
      }
    },
    handleSelectedType(type) {
      if (type.commission_type || type.commission_type === 0) {
        this.commission_type = type.commission_type;
      }
      this.selectedType = type.name;
      this.closeSlideUp();
    },
    handleSelectedDate(time) {
      const today = new Date();
      let startDate = null,
        endDate = null;

      if (time.value === "Custom Date") {
        this.timeCustom = true;
        return;
      }

      if (time.value === "Show All") {
        this.start = "";
        this.end = "";
      } else if (time.value === "Today") {
        startDate = new Date();
        endDate = new Date();
      } else if (time.value === "This Week") {
        const now = new Date();
        const firstDayOfWeek = new Date(now);
        firstDayOfWeek.setDate(now.getDate() - now.getDay());
        startDate = firstDayOfWeek;
        endDate = new Date();
      } else if (time.value === "This Month") {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date();
      }

      this.start = startDate ? startDate.toISOString().split("T")[0] : "";
      this.end = endDate ? endDate.toISOString().split("T")[0] : "";
      this.selectedTime = time.name;
      this.closeSlideUp();
    },

    handleDateChange(value, type) {
      if (type === "start") {
        this.selectedStartDate = value;
      } else {
        this.selectedEndDate = value;
      }
    },
    handleFetchError(error) {
      this.loadingGames = false;
      if (error.response) {
        if (parseInt(error.response.data.status) === 401) {
          this.setError(`${this.$t("sessionExpired")}`);
          this.logout();
          return;
        }
        this.setError(error.response.data.error_message);
      }
    },
  },
  computed: {
    profile: function () {
      return this.myProfile;
    },
  },
  watch: {
    commission_type() {
      this.page = 1;
      this.last_page = 1;
      this.loading = true;
      this.myTransactions();
    },
    active_menu() {
      this.page = 1;
      this.loading = true;
      this.last_page = 1;
      this.myTransactions();
    },
    start() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
    end() {
      this.loading = true;
      this.page = 1;
      this.myTransactions();
    },
  },

  mounted() {
    if (!this.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }
    this.myTransactions();
  },
};
</script>

<style src="./index.css" scoped></style>
