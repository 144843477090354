<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('forgotPin')" />
    </div>

    <section class="register-form-wrapper p-4" v-if="isForgetPin">
      <h3 class="text-center">{{ $t("forgotPin") }}?</h3>
      <div class="text-center mb-4">
        <p>
          {{ $t("enterRegisteredPhoneRestPin") }}
        </p>
      </div>
      <div class="form-wrapper">
        <TextInput
          type="number"
          v-model="msisdn"
          :placeholder="$t('phoneNumber')"
          inputmode="numeric"
          readonly
        />
      </div>

      <ChopbetButton
        :loading="loading"
        variant="Variant"
        @click="handleForgetPin"
        ><span style="text-transform: uppercase">{{ $t("continue") }}</span>
      </ChopbetButton>
    </section>
    <div class="register-form-wrapper p-4" v-else>
      <h5 class="text-center">{{ $t("enterOtpAndPassword") }}</h5>
      <div class="text-center mb-4">
        <p>
          {{ $t("sentCodeToPhone") }} <br />
          <strong>+{{ `225${this.msisdn}` }}</strong>
        </p>
      </div>
      <div
        class="verification-code-wrapper mb-3"
        style="display: flex; justify-content: space-between; gap: 10px"
      >
        <input
          v-for="(value, index) in code"
          :key="index"
          class="otp__input"
          type="number"
          inputmode="numeric"
          maxlength="1"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          :name="generateRandomName(`${code[index]}`)"
          v-model.trim="code[index]"
          @input="handleInput(index, $event)"
          @keydown="blockInvalidChar"
          @keydown.backspace="handleBackspace(index)"
          @focus="handleFocus(index)"
        />
      </div>
      <div>
        <p
          v-if="resendLoading"
          style="text-transform: capitalize; text-align: center"
          class="mb-3"
        >
          {{ $t("loading") }}...
        </p>

        <div
          v-if="allowResendTimer"
          class="login-button-wrapper text-center p-3 mb-0"
        >
          <div
            class="text-center mb-3 allow_resend_text"
            :style="{ color: resendSecond > 0 ? 'gray' : 'var(--dark-red)' }"
          >
            {{ $t("resendCodein") }} {{ this.resendSecond }} {{ $t("seconds") }}
          </div>
        </div>
        <div
          v-if="allowResend && !resendLoading && !allowResendTimer"
          class="login-button-wrapper text-center p-3"
        >
          <div
            class="text-center allow_resend_text"
            style="color: var(--dark-red)"
            @click="tokenResend"
          >
            <span>{{ $t("resendCodeText") }}</span>
          </div>
        </div>
      </div>

      <form id="login-form">
        <div style="margin-bottom: 1rem">
          <TextInput
            type="password"
            v-model="newPin"
            :placeholder="$t('newPin')"
            :inputId="'newPin'"
            :inputClass="'modify-input'"
            :onlyNumbers="true"
            maxLength="4"
          />
        </div>
        <div>
          <TextInput
            type="password"
            v-model="confirmPin"
            :placeholder="$t('confirmPin')"
            :inputId="'confirmPin'"
            :inputClass="'modify-input'"
            :onlyNumbers="true"
            maxLength="4"
          />
        </div>
        <div
          class="disclaimer"
          style="padding: 4px 0 10px 0; margin-bottom: 30px"
        >
          <template v-if="!passwordsMatch">
            <p class="confirm_pass_error" style="color: #dd4646">
              {{ $t("pinDoNotMatch") }}
            </p>
          </template>
        </div>
      </form>
      <ChopbetButton
        :loading="spinner"
        variant="Variant"
        :disabled="isDisabled"
        @click="handleResetPin"
        ><span style="text-transform: uppercase">{{ $t("continue") }}</span>
      </ChopbetButton>
    </div>
  </div>
</template>

<script>
import axios from "@/services/identity";
import { mapState } from "vuex";
import TextInput from "../../components/ui/TextInput.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import BackComponent from "../../components/ui/BackComponent.vue";
import identity from "../../services/identity";

export default {
  name: "ForgotPin",
  components: {
    TextInput,
    ChopbetButton,
    BackComponent,
  },
  comments: {},
  data: function () {
    return {
      resendLoading: false,
      allowResend: false,
      allowResendTimer: true,
      resendSecond: 30,
      msisdn: this.getProfile().msisdn.slice(3),
      newPassword: "",
      password: "",
      confirmPin: "",
      newPin: "",
      confirmPassword: "",
      error: [],
      warning: [],
      success: [],
      spinner: false,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      myProfile: this.getProfile(),
      code: new Array(4).fill(""),
      fullCode: "",
      countdownInterval: null,
      isForgetPin: true,
      loading: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
    passwordsMatch() {
      return this.newPin === this.confirmPin || !this.confirmPin;
    },

    isDisabled() {
      const res =
        !this.passwordsMatch ||
        !this.newPin ||
        !this.confirmPin ||
        this.code.join("").length < 4;
      return res;
    },
    ...mapState(["referralcode"]),
  },
  methods: {
    handleChangePassword(event) {
      this.changePassword(event);
    },
    changePassword: function () {
      this.reset();

      if (this.password.length < 4) {
        this.setError(`${this.$t("PasswordMoreThan4")}`);
        return;
      }

      this.spinner = true;

      var vm = this;
      var path = `/password?lang=${this.$i18n.locale}`;

      const payload = {
        new_password: this.newPassword,
        old_password: this.password,
      };

      axios
        .patch(path, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json",
            lang: this.$i18n.locale || "fr",
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.spinner = false;
          var status = res.status;

          if (parseInt(status) === 201 || parseInt(status) === 200) {
            vm.setSuccess(`${this.$t("passwordUpdated")}`);
            this.$router.push("/my-account");
            return;
          }
        })
        .catch((err) => {
          vm.spinner = false;

          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
          }
        });
    },
    // trackResetButtonClick(event) {
    //   pushToDataLayer("gtm.reset", {
    //     category: "Button",
    //     action: "Click",
    //     label: "Reset",
    //     element: event.target,
    //   });
    // },
    getResetCode: function (event) {
      this.reset();
      if (this.msisdn && this.msisdn.toString().length < 9) {
        this.setError(`${this.$t("pleaseEnterValidMobile")}`);
        return;
      }

      const numberValue = `225${this.msisdn}`;

      this.spinner = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_URL_PASSWORD_RESET}?lang=${this.$i18n.locale}`;

      var payload = {
        msisdn: parseInt(numberValue),
      };

      axios
        .patch(path, payload, {
          headers: {
            "Content-Type": "application/json",
            lang: "fr",
          },
        })
        .then((res) => {
          vm.spinner = false;
          vm.loading = "";
          vm.trackResetButtonClick(event);

          this.setSuccess(
            `${res?.data?.message || this.$t("passwordResetCode")}`
          );

          localStorage.setItem("mssdn__reg_xpk_f", `${this.msisdn}`);

          vm.$router.push("/reset-password");
        })
        .catch((err) => {
          vm.spinner = false;
          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
            // console.log(JSON.stringify(err));
          }
        });
    },

    generateRandomName(baseName) {
      return `${baseName}-${Math.random().toString(36).substring(7)}`;
    },
    handleInput(index, event) {
      const value = event.target.value;
      this.code[index] = value.replace(/[^0-9]/g, "").slice(0, 1);
      if (event.target.value.length === 1) {
        const nextInput = this.$el.querySelectorAll(".otp__input")[index + 1];
        if (nextInput) nextInput.focus();
      }
    },
    handleBackspace(index) {
      if (this.code[index] === "") {
        const prevInput = this.$el.querySelectorAll(".otp__input")[index - 1];
        if (prevInput) prevInput.focus();
      }
    },
    blockInvalidChar(e) {
      if (["e", "E", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      }
    },
    handleFocus(index) {
      if (this.code[index] === "0") {
        this.code[index] = "";
      }
    },
    startCountdown() {
      this.resendSecond = 30;
      this.allowResend = false;
      this.allowResendTimer = true;

      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
      }
      this.countdownInterval = setInterval(() => {
        this.resendSecond--;

        if (this.resendSecond === 0) {
          clearInterval(this.countdownInterval);
          this.allowResend = true;
          this.allowResendTimer = false;
        }
      }, 1000);
    },
    tokenResend: function () {
      this.reset();

      if (this.msisdn === null) {
        this.setError(`${this.$t("invalidPhoneNumber")}`);
        return;
      }

      this.resendLoading = true;
      this.loading = "loading";

      var vm = this;
      var path = `${process.env.VUE_APP_BASE_IDENTITY_URL}/token/resend?lang=${this.$i18n.locale}`;
      var currentDate = new Date();
      var login_tag = parseInt(
        currentDate.getTime() +
          "" +
          Math.floor(Math.random() * (9 * Math.pow(10, 2))) +
          Math.pow(10, 2)
      );
      this.setValue("login_tag", login_tag);

      var payload = {
        phone_number: `225${this.msisdn}`,
      };

      axios
        .post(path, payload, {
          headers: {
            "Content-Type": "application/json",
            lang: this.$i18n.locale || "fr",
          },
        })
        .then((res) => {
          var profile = res.data;

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);
          vm.resendLoading = false;
          vm.loading = "";

          vm.startCountdown();

          vm.$nextTick(() => {
            vm.setSuccess(`${res?.data?.message || this.$t("resentOtp")}`);
          });
        })
        .catch((err) => {
          vm.resendLoading = false;
          vm.loading = "";
          if (err.response) {
            this.setError(err.response.data.error_message);
          } else if (err.request) {
            this.setError(`${this.$t("checkYourNetwork")}`);
          } else {
            this.setError(`${this.$t("checkYourNetwork")}`);
          }
        });
    },
    async handleForgetPin() {
      const payload = {
        msisdn: Number(this.myProfile.msisdn),
      };
      this.loading = true;
      try {
        var path = "/password/forgot";
        const res = await identity.patch(path, payload, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        if (res.status === 200) {
          this.isForgetPin = false;
          this.startCountdown();
          this.setSuccess("Reset code sent successfully");
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          const errorCode = parseInt(err.response.data.status);
          if (errorCode === 401 || errorCode === 428) {
            this.setError(err.response.data.message);
            this.logout();
            return;
          } else {
            this.setError(err.response.data.error_message);
          }
        } else if (err.request) {
          this.setError(
            `${this.$t("networkError")}`,
            `${this.$t("checkYourNetwork")}`
          );
        } else {
          // console.log(JSON.stringify(err));
        }
      }
    },
    async handleResetPin() {
      const payload = {
        code: Number(this.code.join("")),
        pin: Number(this.newPin),
      };
      this.spinner = true;
      try {
        var path = "/pin/reset";
        const res = await identity.post(path, payload, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        if (res.status === 200) {
          // this.isForgetPin = false;
          this.setSuccess(`${this.$t("pinCreatedSuccessfully")}`);
          this.$router.push("/my-account");
        }
        this.spinner = false;
      } catch (err) {
        this.spinner = false;
        if (err.response) {
          const errorCode = parseInt(err.response.data.status);
          if (errorCode === 401 || errorCode === 428) {
            this.setError(err.response.data.message);
            this.logout();
            return;
          } else {
            this.setError(err.response.data.error_message);
          }
        } else if (err.request) {
          this.setError(
            `${this.$t("networkError")}`,
            `${this.$t("checkYourNetwork")}`
          );
        } else {
          // console.log(JSON.stringify(err));
        }
      }
    },
  },
  mounted: function () {
    var vm = this;

    vm.myProfile = vm.getProfile();

    if (!vm.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }
    // this.$store.dispatch("setCurrentPage", "change-password");
  },
};
</script>

<style scoped>
.otp__input {
  border: 1px var(--otp-input-border) solid;
  border-radius: 5px;
  background-color: transparent;
  color: var(--text-color);
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 24px;
}
.otp__input:focus {
  border: 1px solid var(--input-border-focus) !important;
  outline: none;
}
p,
h3 {
  color: var(--text-color);
}
</style>
