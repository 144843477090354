<template>
  <div class="wrapper">
    <div class="body" style="padding: 0">
      <ShimmerCasino v-if="isLoading"></ShimmerCasino>
      <NoData
        :title="$t('noGame')"
        :description="$t('noGameOnCategory')"
        v-if="(!isLoading && allgamesdata.length < 1) || !allgamesdata"
      />
      <div
        class="scroller"
        id="scroller"
        v-if="!isLoading && allgamesdata && allgamesdata.length > 0"
      >
        <div
          class="casino-game"
          style="background-color: var(--background-color)"
        >
          <div
            style="text-align: center"
            v-for="(i, x) in allgamesdata"
            v-bind:key="getKey(x)"
          >
            <div class="game-icon">
              <a @click="openSlideUp(i)">
                <img
                  loading="lazy"
                  :src="getImg(i.image)"
                  alt="Avatar"
                  @error="handleImageError($event)"
                  data-toggle="modal"
                  :data-target="'#casinoGames' + i.game_id"
                />
              </a>
              <p style="color: var(--icons-color)">
                <span>{{ truncateFirstWord(i.game_name) }}</span>
                <!-- <span>{{ i.game_name }}</span> -->
                <img style="width: 15px" src="/menu/star.svg" />
              </p>
            </div>
          </div>
        </div>
      </div>
      <ChopbetSlideUp
        :isOpen="isSlideUpOpen"
        @closeSlideup="closeSlideUp"
        :fullHeight="false"
      >
        <div :class="isLoaded ? 'tick' : 'chopslide'">
          <div class="chopslide-heading" v-show="loading || isLoaded">
            <div class="choplogo">
              <ChopbetLogo />
            </div>
            <div class="close-btn">
              <div class="control-button" v-if="isLoaded">
                <router-link to="/deposit" class="special-font change">
                  <ChopbetButton :casino="true">
                    {{ $t("deposit") }}</ChopbetButton
                  >
                </router-link>
              </div>
              <div class="close" @click="closeSlideUp">
                <CloseIcon />
              </div>
            </div>
          </div>

          <iframe
            allow="fullscreen"
            v-show="isLoaded"
            class="casino-iframe"
            v-bind:src="launchURL"
            allowfullscreen
            webkitallowfullscreen
          ></iframe>
          <div class="preview-game" v-show="!loading && !isLoaded">
            <div class="modal-img">
              <img
                v-if="previewGame"
                loading="lazy"
                v-bind:src="previewGame.image"
                alt="Avatar"
                data-toggle="modal"
                @error="handleImageError($event)"
                :data-target="'#casinoGames' + previewGame.game_id"
              />
              <div class="preview-name">
                <p>{{ previewGame.game_name }}</p>
                <p v-show="isJackpot">
                  <span style="display: flex; align-items: center"
                    ><span class="star">&#x22C6;</span>
                    <span class="jack"> Jackpot</span></span
                  >
                  <span
                    >{{ previewGame?.jackpot_data?.amount }}
                    {{ fiatCurrency }}</span
                  >
                </p>
              </div>
            </div>
            <div class="control-button">
              <ChopbetButton
                @click="getLaunchUrl(previewGame)"
                :loading="loading"
              >
                {{ $t("playGames") }}
              </ChopbetButton>
            </div>
            <div
              v-show="previewGame?.demo === 1 && previewGame?.provider_id === 4"
              style="margin-top: 0.4rem"
            >
              <ChopbetButton
                variant="outline"
                @click="getLaunchUrl(previewGame, 'demo')"
                :loading="loading"
              >
                {{ $t("playDemo") }}
              </ChopbetButton>
            </div>
          </div>
          <div class="slide-loading" v-show="loading">
            <p>{{ $t("loading") }}...</p>
            <div class="progress-track">
              <div class="level" :style="{ width: 70 + '%' }"></div>
            </div>
          </div>
        </div>
      </ChopbetSlideUp>
    </div>
  </div>
</template>

<style scoped src="../index.css"></style>

<script>
import ShimmerCasino from "@/components/shimmer/ShimmerCasino.vue";
import ChopbetSlideUp from "@/components/ui/ChopbetSlideUp.vue";
import providerServiceMap from "@/services/providerServiceMap";
import getProviderPayload from "@/utils/getProviderPayload";
import NoData from "@/components/ui/NoData.vue";
import ChopbetButton from "@/components/ui/ChopbetButton.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import ChopbetLogo from "@/components/icons/ChopbetLogo.vue";

export default {
  name: "GamesCasino",

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    allgamesdata: {
      type: Array,
      default: () => [],
    },
    isJackpot: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CloseIcon,
    ShimmerCasino,
    ChopbetButton,
    ChopbetSlideUp,
    NoData,
    ChopbetLogo,
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      isSlideUpOpen: false,
      previewGame: {},
      isLoaded: false,
      loading: false,
    };
  },
  methods: {
    handleImageError(event) {
      event.target.src = "/img/casino-default.svg";
    },
    openSlideUp(game) {
      this.previewGame = game;
      this.isSlideUpOpen = true;
    },
    closeSlideUp() {
      if (this.promoGames.game_name) {
        this.$router.push("/games");
      }
      this.isLoaded = false;
      this.isLoading = false;
      this.isSlideUpOpen = false;
    },
    truncateFirstWord(gameName) {
      const firstWord = gameName.split(" ")[0];
      return firstWord.length > 7
        ? firstWord.substring(0, 7) + "..."
        : firstWord;
    },
    getLaunchUrl(data, type) {
      if (!this.profile) {
        this.setError(this.$t("pleaseLoginProceed"));
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      this.loading = true;
      var providerId = data.provider_id;
      var gameId = data.game_id;
      var demo = type === "demo" ? Number(1) : Number(0);
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);
      var device_type = this.isMobile() ? "mobile" : "desktop";
      let payload = getProviderPayload(
        providerId,
        accountID,
        gameId,
        gameName,
        device_type,
        demo
      );
      if (!payload) {
        this.loading = false;
        return;
      }
      return this.launchGame(payload);
    },
    launchGame: function (payload) {
      var vm = this;
      var providerId = payload.providerId;
      var service = providerServiceMap[providerId];
      if (!service) {
        this.loading = false;
        // console.error("Invalid providerId:", providerId);
        return;
      }

      console.log(service ,'serviceserviceserviceserviceservice');
      
      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          vm.launchURL = response.data.game_url;
          if (vm.launchType === "3") {
            window.open(this.launchURL);
          }
          this.isLoaded = true;
          this.loading = false;
          return vm.launchURL;
        })
        .catch((error) => {
          this.loading = false;
          this.closeSlideUp();
          console.error("Error launching game:", error);
        });
    },
    getImg: function (img) {
      return img.replaceAll("/rec/325/", "/square/200/");
    },
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },
  },
  computed: {
    promoGames: function () {
      const promoGame = this.$route.params;
      return promoGame;
    },
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
  },
  watch: {
    allgamesdata(newData) {
      if (newData.length > 0 && this.promoGames?.game_name) {
        const filterGame = newData.find(
          (x) =>
            x.game_name.toLowerCase() ===
            this.promoGames.game_name.toLowerCase()
        );
        if (filterGame) {
          this.openSlideUp(filterGame);
        }
      }
    },
  },
};
</script>
