<template>
  <div class="wrapper">
    <div class="body" style="padding: 0">
      <Banner :imagesFr="imagesFr" :imagesEn="imagesEn" />

      <div class="cas-header-wrapper">
        <div class="casino-scroll" id="catsec">
          <div
            class="casino-col"
            v-for="(item, index) in categories"
            @click="updateCategory(item)"
            :class="isActive(item)"
            v-bind:key="getKey(index)"
          >
            <span>
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>

      <GamesCasino
        :allgamesdata="allgamesdata"
        :isLoading="isLoading"
        :isJackpot="isJackpot"
      />
      <div v-if="allgamesdata && allgamesdata.length > 0">
        <scroll-loader
          v-show="allgamesdata.length > 0"
          :loader-method="loadOnScroll"
          :loader-disable="is_busy || currentPage >= last_page"
        >
          <div></div>
        </scroll-loader>
      </div>
    </div>
  </div>
</template>

<style scoped src="./index.css"></style>

<script>
import Vue from "vue";
import ScrollLoader from "vue-scroll-loader";
import casino from "@/services/casino";
import Banner from "@/components/banner/Banner.vue";
import GamesCasino from "./components/GamesCasino.vue";

Vue.use(ScrollLoader);
export default {
  name: "Games",
  components: {
    GamesCasino,
    Banner,
  },
  data: function () {
    return {
      isLoggedIn: this.getProfile(),
      isLoading: true,
      previewGame: {},
      isSlideUpOpen: false,
      slideUp: "",
      totals: [],
      password: "",
      code: "",
      isLoaded: false,
      message: "",
      allgamesdata: [],
      to_search: "",
      is_busy: false,
      searchgamesdata: [],
      allgames: [],
      categories: [],
      casinoCategory: 9,
      casinoCategoryActive: null,
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      currentPage: 1,
      per_page: 30,
      last_page: 1,
      imagesFr: [
        {
          src: "/img/banners/gifts/taxi-crash-fr.webp",
          isLoggedIn: false,
          label: "telegram",
          route: `/games/crash/Taxi Ride`,
        },
        {
          src: "/img/banners/gifts/fly-comet-fr.webp",
          isLoggedIn: false,
          label: "telegram",
          route: `/games/crash/Comet`,
        },
      ],
      imagesEn: [
        {
          src: "/img/banners/gifts/taxi-crash-en.webp",
          isLoggedIn: false,
          label: "telegram",
          route: `/games/crash/Taxi Ride`,
        },
        {
          src: "/img/banners/gifts/fly-comet-en.webp",
          isLoggedIn: false,
          label: "faq",
          route: `/games/crash/Comet`,
        },
      ],
    };
  },

  mounted: function () {
    // if (!this.isLoggedIn) {
    //   this.$router.push("/login");
    //   return;
    // }
    this.$store.dispatch("setCurrentPage", "casino");
    if (!this.promoGames.category) {
      this.getCategories();
    }

    // var p = this.getProfile();
    // if (!p) {
    //   console.log("");
    // } else {
    //   this.$router.push({ name: "home", params: {} });
    //   return;
    // }
  },

  methods: {
    loadOnScroll: function () {
      var vm = this;
      if (vm.allgamesdata) {
        vm.getCasinoGames(vm.casinoCategoryActive);
      }
    },

    isActive: function (item) {
      if (item.id == this.casinoCategoryActive.id) {
        return " active";
      } else {
        return "";
      }
    },
    closeModal: function () {
      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add("d-none");
    },
    updateCategory: function (category) {
      this.casinoCategoryActive = category;
    },
    async getCasinoGames(activeCategory) {
      const vm = this;
      if (vm.is_busy || parseInt(vm.currentPage) > parseInt(vm.last_page)) {
        vm.is_busy = false;
        return;
      }

      if (vm.currentPage === 1) {
        vm.is_busy = true;
        vm.isLoading = true;
      }
      try {
        var path = `/games?page=${vm.currentPage}&per_page=${vm.per_page}&category_id=${activeCategory.id}`;
        if (activeCategory.name === "All") {
          path = `/games?page=${vm.currentPage}&per_page=${vm.per_page}`;
        }
        const resp = await casino.get(path);
        if (
          !resp?.data?.data ||
          resp.data.data.length === 0 ||
          resp?.data?.data === null
        ) {
          vm.allgamesdata = [];
          vm.currentPage = 1;
          vm.last_page = 1;
          vm.isLoading = false;
          vm.is_busy = false;
          return;
        }

        vm.last_page = resp.data.last_page;
        vm.allgamesdata = [...vm.allgamesdata, ...resp.data.data];
        vm.currentPage = parseInt(vm.currentPage) + 1;
      } catch (error) {
        console.error("Error fetching games:", error);
      } finally {
        this.is_busy = false;
        this.isLoading = false;
      }
    },
    performSearch: function () {
      var keyword = this.to_search;
      var games = this.allgames;
      var results = games.filter(function (el) {
        var game_name = el.game_name.toLowerCase();

        return game_name.match(keyword.toLowerCase()) == null
          ? false
          : game_name.match(keyword.toLowerCase()).length > 0;
      });

      if (results.length > 0 && keyword !== "") {
        this.searchgamesdata = results;
      } else {
        this.searchgamesdata = [];
      }
    },
    keyUpSearch: function () {
      if (this.to_search == "") {
        this.searchgamesdata = [];
      }
    },
    loadPage: function (launch_url) {
      window.open(launch_url, "_blank");
    },
    getImg: function (img) {
      return img.replaceAll("/rec/325/", "/square/200/");
    },
    getKey: function (index) {
      return Math.random()
        .toString(10)
        .replace("0.", "games-id-" + index + "-");
    },
    async getCategories() {
      try {
        const path = "/category";
        const res = await casino.get(path);

        this.categories = res.data;
        const promo = this.promoGames;

        if (promo.category) {
          const getCategory = this.categories.find(
            (x) => x.name.toLowerCase() === promo.category.toLowerCase()
          );
          this.casinoCategoryActive = getCategory;
          await this.getCasinoGames(getCategory);
        } else {
          this.casinoCategoryActive = res.data[0];
          await this.getCasinoGames(res.data[0]);
        }
      } catch (err) {
        if (err.response) {
          // this.setError(err.response.data.error_message);
        } else if (err.request) {
          // console.log(JSON.stringify(err.request));
        } else {
          // console.log(JSON.stringify(err));
        }
      } finally {
        this.isLoading = false;
        // Optional: Perform any cleanup or final steps here if needed
        // Example: this.loading = false;
      }
    },
  },

  filters: {
    amount: function (val) {
      if (val === undefined) {
        return val;
      }
      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },

  computed: {
    promoGames: function () {
      const promoGame = this.$route.params;
      return promoGame;
    },
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
    // isJackpot: function () {
    //   return this.casinoCategoryActive.id === 16;
    // },
    isJackpot: function () {
      return this.casinoCategoryActive && this.casinoCategoryActive.id === 16;
    },
  },
  watch: {
    casinoCategoryActive() {
      this.last_page = 1;
      this.currentPage = 1;
      this.allgamesdata = [];
      this.getCasinoGames(this.casinoCategoryActive);
    },
    "$route.params": {
      handler(newParams) {
        if (!this.$route.params.category) {
          console.log(newParams);
          return;
        }
        this.$store.dispatch("setCurrentPage", "casino");
        this.getCategories();
      },
      immediate: true, // Trigger immediately on component load
      deep: true, // Watch for nested object changes, if necessary
    },
  },
};
</script>
