<template>
  <div>
    <div v-if="showSuccess" class="success page_container">
      <span class="rotate">
        <SuccessIcon />
      </span>
      <h3>{{ isWidthraw ? $t("withdrawalRequest") : $t("depositRequest") }}</h3>
      <p>
        {{
          isWidthraw ? $t("yourWithdrawalRequest") : $t("yourDepositRequest")
        }}
      </p>
      <router-link to="/my-account">
        <ChopbetButton variant="outline">{{ $t("backToGame") }} </ChopbetButton>
      </router-link>
    </div>
    <div v-else-if="waveFrame" class="waveFrame">
      <iframe
        :src="waveFrameUrl"
        frameborder="0"
        style="width: 100%; height: 100vh"
      ></iframe>
      <!-- <a :href="waveFrameUrl" target="_blank" class="btn_wave">
        <ChopbetButton>Go to wave</ChopbetButton>
      </a> -->
    </div>

    <div class="page_container payment" v-else>
      <BackComponent
        :title="isWidthraw ? $t('withdrawFunds') : $t('depositFunds')"
      />
      <div class="wallet-info">
        <div class="account">
          <div>
            <img
              :src="filteredPaymentProviders.imgSrc"
              style="width: 34px; height: 18px"
            />
            <span>{{ filteredPaymentProviders.name }}</span>
          </div>
          <router-link
            :to="isWidthraw ? '/withdraw' : '/deposit'"
            class="change"
          >
            {{ $t("changeMethod") }}
          </router-link>
        </div>
      </div>

      <div class="input_wrapper phone_number">
        <div class="country">
          <div>
            <CountryFlag />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.28 5.9668L8.9333 10.3135C8.41997 10.8268 7.57997 10.8268 7.06664 10.3135L2.71997 5.9668"
                stroke="#BAB7B7"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span>+225</span>
        </div>
        <label for="">{{ $t("phoneNumber") }}</label>
        <input
          type="text"
          aria-label="Enter Amount"
          :disabled="!isDevelopment"
          name="phonenumber"
          v-model="phonenumber"
          @input="validateNumber"
          maxlength="10"
        />
      </div>

      <!-- isChopAgent -->
      <div v-show="isChopAgent">
        <div class="input_wrapper phone_number">
          <div class="country">
            <div>
              <CountryFlag />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M13.28 5.9668L8.9333 10.3135C8.41997 10.8268 7.57997 10.8268 7.06664 10.3135L2.71997 5.9668"
                  stroke="#BAB7B7"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <span>+225</span>
          </div>
          <label for="">
            {{
              isWidthraw ? `${$t("agentNumber")}` : `${$t("playerPhoneNumber")}`
            }}
          </label>
          <input
            type="text"
            :aria-label="
              isWidthraw ? `${$t('agentNumber')}` : `${$t('playerNumber')}`
            "
            name="agentReciept"
            v-model="agentReciept"
            @input="validateAgentNumber"
            maxlength="10"
          />
        </div>
        <div class="input_wrapper" style="margin-bottom: 1rem">
          <input
            type="texts"
            :placeholder="
              isWidthraw ? `${$t('agentPlayerID')}` : `${$t('playerID')}`
            "
            :aria-label="
              isWidthraw ? `${$t('agentPlayerID')}` : `${$t('playerID')}`
            "
            name="playerId"
            v-model="playerId"
            maxlength="10"
          />
        </div>
      </div>

      <div class="input_wrapper">
        <input
          type="texts"
          :placeholder="`${
            isWidthraw
              ? `${$t('withdraw')} ${$t('amount')}`
              : $t('amountRecharge')
          } (${
            isWidthraw
              ? filteredPaymentProviders.minWithrawal
              : filteredPaymentProviders.minDeposit
          } - ${comaFormated(
            isWidthraw
              ? filteredPaymentProviders.maxWithrawal
              : filteredPaymentProviders.maxDeposit,
            lang
          )} ${fiatCurrency})`"
          aria-label="Enter Amount"
          name="amount"
          v-model="amount"
          @input="validateInput"
          maxlength="10"
        />
      </div>
      <div class="auto_add">
        <button
          @click="setAmount(filteredPaymentProviders.minWithrawal)"
          v-if="isWidthraw"
        >
          +{{ filteredPaymentProviders.minWithrawal }}
        </button>
        <button @click="setAmount(filteredPaymentProviders.minDeposit)" v-else>
          +{{ filteredPaymentProviders.minDeposit }}
        </button>
        <button @click="setAmount(500)">+500</button>
        <button @click="setAmount(1000)">
          +{{ comaFormated("1000", lang) }}
        </button>
        <button @click="setAmount(2000)">
          +{{ comaFormated("2000", lang) }}
        </button>
        <button @click="setAmount(5000)">
          +{{ comaFormated("5000", lang) }}
        </button>
        <button @click="setAmount(10000)">
          +{{ comaFormated("10000", lang) }}
        </button>
      </div>
      <div class="input_wrapper" v-if="isOtp">
        <input
          type="tel"
          :placeholder="`${$t('inputOTP')}`"
          :aria-label="`${$t('inputOTP')}`"
          name="otp"
          v-model="otp"
          @input="validateOtp"
          maxlength="4"
          @keypress="onlyNumbers"
        />
        <p><span class="code">#144*82#</span> {{ $t("toGenerateOtp") }}</p>
      </div>
      <div v-show="isChopAgent">
        <TextInput
          :placeholder="`${$t('inputPin')}`"
          :aria-label="`${$t('inputPin')}`"
          name="otp"
          v-model="otp"
          :onlyNumbers="true"
          maxLength="4"
          type="password"
        />
        <div class="forgot-pin">
          <p>{{ $t("enterYour4DigitPin") }}</p>
          <router-link to="/forgot-pin">Forgot Pin?</router-link>
        </div>
      </div>
      <ChopbetButton
        variant="primary"
        @click="handleSubmitClick"
        :disabled="disabled"
        :loading="loading"
      >
        {{ $t("continue") }}</ChopbetButton
      >
      <div class="message">
        <h4 @click="slideUp()" v-if="!isWidthraw">
          {{
            isWidthraw
              ? $t("withdrawNotReflecting")
              : $t("depositNotReflecting")
          }}

          ? {{ " " }}<span>{{ $t("openHelpCenter") }}</span>
        </h4>

        <div class="info" v-if="isWidthraw">
          <h4>{{ $t("howWithdrawalWorks") }}</h4>
          <p
            v-for="(
              instruction, index
            ) in filteredPaymentProviders.widthrawInfo"
            :key="index"
          >
            <span>{{ index + 1 }}.</span> {{ instruction }}
          </p>
        </div>
        <div v-else>
          <div class="info">
            <h4>{{ $t("howDepositWorks") }}</h4>
            <p
              v-for="(
                instruction, index
              ) in filteredPaymentProviders.depositInfo"
              :key="index"
            >
              <span>{{ index + 1 }}.</span> {{ instruction }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <div class="help_center_modal">
        <div class="close">
          <span @click="closeSlideUp()"><CancelIcon /></span>
        </div>
        <div class="help_center">
          <div>
            <p>{{ $t("depositHelpCenter") }}</p>
            <span
              >{{ $t("pasteConfirmationMessage") }}
              {{ filteredPaymentProviders.name }}
              {{ $t("confirmationMessage") }}</span
            >
          </div>

          <div class="input_wrapper">
            <input
              type="texts"
              :placeholder="$t('transactionId')"
              aria-label="Enter Transaction id"
              name="transaction_id"
              v-model="transaction_id"
            />
          </div>
          <ChopbetButton
            @click="processMessage()"
            :disabled="transaction_id.length < 10"
            :loading="spinner"
          >
            {{ $t("processMessage") }}</ChopbetButton
          >
        </div>
      </div>
    </ChopbetSlideUp>
  </div>
</template>

<script>
import walletserve from "../../services/walletserve";
import BackComponent from "./BackComponent.vue";
import ChopbetButton from "./ChopbetButton.vue";
import SuccessIcon from "../icons/SuccessIcon.vue";
import CountryFlag from "../icons/CountryFlag.vue";
import { comaFormated } from "../../utils/formatter";
import ChopbetSlideUp from "./ChopbetSlideUp.vue";
import CancelIcon from "../icons/CancelIcon.vue";
import { processHelpCenter } from "../../actions/wallet";
import TextInput from "./TextInput.vue";

export default {
  name: "DepositComponent",
  data() {
    return {
      isSlideUpOpen: false,
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      amount: "",
      transaction_id: "",
      otp: "",
      disabled: true,
      loading: false,
      showSuccess: false,
      myProfile: this.getProfile(),
      phonenumber: this.getProfile()?.msisdn
        ? String(this.getProfile()?.msisdn).slice(3)
        : "",
      paymentProviders: [
        {
          name: "Wave",
          imgSrc: "/img/payment/wave.webp",
          link: "/deposit/wave",
          key: "wave",
          minDeposit: 5,
          minWithrawal: 500,
          maxDeposit: 2000000,
          maxWithrawal: 2000000,
          depositInfo: [
            `${this.$t("sufficientWalletBalanceWave")}`,
            `${this.$t("depositAmountWave")}`,
            `${this.$t("scanQrCodeWave")}`,
            `${this.$t("depositsuccessfulWave")}`,
          ],
          widthrawInfo: [
            `${this.$t("sufficientWalletBalanceWithdrawal")}`,
            `${this.$t("enterWithdrawalAmount")}`,
            `${this.$t("scanQrCode")}`,
            `${this.$t("waitWithdrawalSuccess")}`,
          ],
        },
        {
          name: "Orange Money",
          imgSrc: "/img/payment/orange.webp",
          link: "/deposit/orange",
          key: "orange",
          minDeposit: 5,
          // minWithrawal: 5,
          minWithrawal: 500,
          maxDeposit: 2500000,
          maxWithrawal: 2500000,
          depositInfo: [
            `${this.$t("checkBalanceAmount")}`,
            `${this.$t("enterDepositOrange")}`,
            `${this.$t("dialCode")}`,
            `${this.$t("enterOtp")}`,
            `${this.$t("depositsuccessful")}`,
          ],
          widthrawInfo: [
            `${this.$t("sufficientWalletBalanceWithdrawal")}`,
            `${this.$t("enterWithdrawalAmount")}`,
            `${this.$t("scanQrCode")}`,
            `${this.$t("waitWithdrawalSuccess")}`,
          ],
        },
        {
          name: "MTN",
          imgSrc: "/img/payment/mtn.webp",
          link: "/deposit/mtn",
          key: "mtn",
          minDeposit: 100,
          // minWithrawal: 100,
          minWithrawal: 500,
          maxDeposit: 2000000,
          maxWithrawal: 2000000,

          depositInfo: [
            `${this.$t("sufficientWalletBalance")}`,
            `${this.$t("depositAmount")}`,
            `${this.$t("scanQrCode")}`,
            `${this.$t("depositsuccessful")}`,
          ],
          widthrawInfo: [
            `${this.$t("sufficientWalletBalanceWithdrawal")}`,
            `${this.$t("enterWithdrawalAmount")}`,
            `${this.$t("scanQrCode")}`,
            `${this.$t("waitWithdrawalSuccess")}`,
          ],
        },
        {
          name: "Moov Money",
          imgSrc: "/img/payment/moov.webp",
          link: "/deposit/moov",
          key: "moov",
          minDeposit: 100,
          minWithrawal: 500,
          maxDeposit: 2000000,
          maxWithrawal: 2000000,
          depositInfo: [
            `${this.$t("sufficientWalletBalanceMoov")}`,
            `${this.$t("depositAmountMoov")}`,
            `${this.$t("scanQrCodeMoov")}`,
            `${this.$t("depositsuccessfulMoov")}`,
          ],
          widthrawInfo: [
            `${this.$t("sufficientWalletBalanceWithdrawal")}`,
            `${this.$t("enterWithdrawalAmount")}`,
            `${this.$t("scanQrCode")}`,
            `${this.$t("waitWithdrawalSuccess")}`,
          ],
        },
        {
          name: "Chop Agent",
          imgSrc: "/img/payment/chopbet-agent.png",
          link: "/deposit/chop-agent",
          key: "chop-agent",
          minDeposit: 100,
          minWithrawal: 500,
          maxDeposit: 2000000,
          maxWithrawal: 2000000,
          depositInfo: [
            `${this.$t("chopAgentTopUpOne")}`,
            `${this.$t("chopAgentTopUpTwo")}`,
            `${this.$t("chopAgentTopUpThree")}`,
            `${this.$t("chopAgentTopUpFour")}`,
          ],
          widthrawInfo: [
            `${this.$t("chopAgentWithdrawOne")}`,
            `${this.$t("chopAgentWithdrawTwo")}`,
            `${this.$t("chopAgentWithdrawThree")}`,
            `${this.$t("chopAgentWithdrawFour")}`,
          ],
        },
      ],
      lang: this.$i18n.locale,
      waveFrame: false,
      waveFrameUrl: "https://www.facebook.com/",
      provider: "paymetrust",
      spinner: false,
      agentReciept: "",
      playerId: "",
    };
  },
  mounted() {
    // console.log(this.title, "title---", this.isWidthraw);
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isWidthraw: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BackComponent,
    ChopbetButton,
    SuccessIcon,
    CountryFlag,
    CancelIcon,
    ChopbetSlideUp,
    TextInput,
  },
  computed: {
    isDevelopment() {
      const params = this.$route.path.split("/")[1];
      // return process.env.VUE_APP_ENVIRONMENT === "development";
      return params === "deposit";
      // return true;
    },
    filteredPaymentProviders() {
      // this.provider = val

      const val = this.paymentProviders.find(
        (provider) => provider.name === this.title
      );
      return val;
    },
    isOtp() {
      const val = this.filteredPaymentProviders.key === "orange";
      if (this.isWidthraw) {
        return false;
      }
      return val;
    },
    isChopAgent() {
      return this.title.toLowerCase() === "chop agent";
    },
  },
  watch: {
    amount: function (val) {
      if (val >= Number(this.filteredPaymentProviders.minDeposit)) {
        if (this.isOtp) {
          this.disabled = true;
          return;
        }
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    otp: function (val) {
      if (val !== "" && this.amount >= 10) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    showSuccess(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$router.push("/");
        }, 5000);
      }
    },
  },
  methods: {
    slideUp() {
      this.isSlideUpOpen = true;
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
    onlyNumbers(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    comaFormated,
    validateInput(event) {
      const value = event.target.value.replace(/[^0-9.]/g, "");
      const decimalCheck =
        value.split(".").length > 2 ? value.replace(/\.+$/, "") : value;
      this.amount = decimalCheck;
    },
    validateOtp(event) {
      const value = event.target.value.replace(/[^0-9.]/g, "");
      const decimalCheck =
        value.split(".").length > 2 ? value.replace(/\.+$/, "") : value;
      this.otp = decimalCheck;
    },
    validateNumber(event) {
      const value = event.target.value.replace(/[^0-9.]/g, "");
      const decimalCheck =
        value.split(".").length > 2 ? value.replace(/\.+$/, "") : value;
      this.phonenumber = decimalCheck;
    },
    validateAgentNumber(event) {
      const value = event.target.value.replace(/[^0-9.]/g, "");
      const decimalCheck =
        value.split(".").length > 2 ? value.replace(/\.+$/, "") : value;
      this.agentReciept = decimalCheck;
    },
    handleSubmitClick(event) {
      this.submitPayment(event);
    },
    submitPayment: async function () {
      var p = this.getAuth();
      var vm = this;
      var path = "/deposit/initiate";

      if (!p) {
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.isWidthraw) {
        path = "/withdraw";
        if (this.amount < Number(this.filteredPaymentProviders.minWithrawal)) {
          this.setError(
            `${this.$t("enterAtLeast100")}${
              this.filteredPaymentProviders.minWithrawal
            } ${this.fiatCurrency} ${this.$t("orAbove")}`
          );
          return;
        }
      } else {
        if (this.amount < Number(this.filteredPaymentProviders.minDeposit)) {
          this.setError(
            `${this.$t("enterAtLeast100")}${
              this.filteredPaymentProviders.minDeposit
            } ${this.fiatCurrency} ${this.$t("orAbove")}`
          );
          return;
        }
      }

      var data = {
        amount: parseInt(this.amount),
        payment_method: this.filteredPaymentProviders.key,
        msisdn: `225${this.phonenumber}`,
      };

      if (this.filteredPaymentProviders.key === "orange") {
        data.otp = this.otp;
      }
      this.loading = true;
      vm.loading = "loading";

      if (this.isChopAgent) {
        if (this.isWidthraw) {
          path = "/agent/withdraw";
          data = {
            agent_id: Number(this.playerId),
            amount: parseInt(this.amount),
            phone_number: `225${this.agentReciept}`,
            pin: this.otp,
          };
        } else {
          path = "/agent/deposit";
          data = {
            player_id: Number(this.playerId),
            amount: parseInt(this.amount),
            phone_number: `225${this.agentReciept}`,
            pin: this.otp,
          };
        }
      }
      try {
        const res = await walletserve.post(path, JSON.stringify(data), {
          headers: {
            "api-key": this.getAuth(),
          },
        });

        if (!this.isWidthraw && this.filteredPaymentProviders.key === "wave") {
          this.waveFrameUrl = res.data.data.payment_url;
          this.waveFrame = true;
        } else {
          this.loading = false;
          this.showSuccess = true;
        }
      } catch (err) {
        this.loading = false;

        if (err.response) {
          const errorCode = parseInt(err.response.data.status);
          if (errorCode === 401 || errorCode === 428) {
            this.setError(err.response.data.message);
            this.logout();
            return;
          } else {
            this.setError(err.response.data.error_message);
          }
        } else if (err.request) {
          this.setError(
            `${this.$t("networkError")}`,
            `${this.$t("checkYourNetwork")}`
          );
        } else {
          // console.log(JSON.stringify(err));
        }
      }
    },
    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
    },
    processMessage: async function () {
      const { transaction_id, provider } = this;
      const apiKey = this.getAuth();
      const params = {
        apiKey,
        transaction_id,
        provider,
      };
      this.spinner = true;
      try {
        const response = await processHelpCenter(params);
        if (response.status === 1) {
          this.setSuccess(
            `${this.$t("yourTransactionOf")} ${response.amount} ${this.$t(
              "hasBeenProcessed"
            )}`
          );
        } else if (response.status === 0) {
          this.setError(
            `${this.$t("yourTransactionOf")} ${response.amount} ${this.$t(
              "isPending"
            )}`
          );
        } else {
          this.setError(
            `${this.$t("yourTransactionOf")} ${response.amount} ${this.$t(
              "couldNotBeProcessed"
            )}`
          );
        }
      } catch (error) {
        this.handleFetchError(error);
      } finally {
        this.spinner = false;
        this.closeSlideUp();
      }
    },
    handleFetchError(error) {
      this.transaction_id = "";
      if (error.response) {
        if (parseInt(error.response.data.status) === 401) {
          this.setError(`${this.$t("sessionExpired")}`);
          this.logout();
          return;
        }
        this.setError(error.response.data.error_message);
      }
    },
  },
};
</script>

<style scoped>
.help_center {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 5px;
  width: 100%;
}

p {
  font-size: 14px;
  margin-bottom: 4px;
  font-weight: 700;
}

span {
  color: var(--sub-text-color);
  white-space: normal;
}

.help_center_modal {
  padding: 12px 20px 60px;
}

.payment {
  padding-bottom: 4rem;
}
.code {
  color: var(--slide-active);
}
.section-title {
  margin: 0;
  font-size: 0.8rem;
  color: var(--text-color);
  font-weight: normal;
}

.wallet-info {
  border-radius: 8px;
  background: var(--box-bg);
  color: var(--text-color);
  margin-bottom: 8px;
}

.wallet-info {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-top: 8px;
  margin-bottom: 2rem;
}

.account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.account span {
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1px;
}
.account > div {
  display: flex;
  align-items: center;
}

.account img {
  width: 24px;
  height: 24px;
  max-width: 24px !important;
  margin-right: 8px;
}
.change {
  color: var(--red-400);
  font-size: 10px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 600;
}

.message > h4 {
  margin-top: 12px;
  margin-bottom: 24px;
  span {
    cursor: pointer;
  }
}
.message h4 span {
  color: var(--red-400);
  /* font-family: "Game Station"; */
}
.message {
  color: var(--text-color);
}

h4 {
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}

.info {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  margin-top: 16px;
  align-self: stretch;
  border-radius: 8px;
  background-color: var(--box-bg);
  color: var(--sub-text-color);
}

.info p {
  color: var(--sub-text-color);
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
  display: flex;
  align-items: flex-start;
}

.info p span {
  width: 16px;
}
.success {
  font-family: "Satoshi";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
  display: flex;
  padding-top: 10vh;
  flex-direction: column;
  text-align: center;
}

.success img {
  margin-bottom: 3rem;
}
.success svg {
  margin: 0 auto;
  margin-bottom: 16px;
}
.success p {
  color: var(--text-color);
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.success h3 {
  color: var(--text-color);
  text-align: center;
  font-family: "Satoshi";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}

.auto_add {
  display: flex;
  justify-content: space-between;
  display: flex;
  overflow-x: scroll;
  padding-bottom: 24px;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}
.auto_add::-webkit-scrollbar {
  display: none;
}
.auto_add button {
  padding: 8px 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
  border-radius: 70px;
  border: 1px solid var(--border);
  color: var(--sub-text-color);
  background-color: var(--background-color);
  margin-right: 4px;
  cursor: pointer;
  min-width: 72px;
}
.auto_add button :hover {
  background-color: var(--background-color);
}

.phone_number {
  position: relative;
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: flex-end;
  gap: 2px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.phone_number label {
  position: absolute;
  top: 6px;
  left: 70px;
  color: #545151;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.phone_number input {
  font-size: 14px;
  padding-top: 4px;
  padding: 10px 0;
  padding-left: 100px;
  padding-top: 30px !important;
}

.country {
  gap: 8px;
  position: absolute;
  top: 17px;
  left: 20px;
  width: 80px;
  display: flex;
  align-items: center;
}
.country span {
  margin-bottom: -22px;
  color: #8c8787;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.14px;
}

.country div:nth-child(1) {
  display: flex;
  align-items: center;
}

.rotate svg {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.waveFrame {
  width: 100%;
  position: relative;
}

.btn_wave {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.chop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chop img {
  width: 24px;
  height: 17px;
  margin-right: 8px;
}

.forgot-pin {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.forgot-pin p {
  width: 50%;
  color: var(--sub-text-color);
}

.forgot-pin a {
  width: 50%;
  text-align: right;
  color: var(--red-400) !important;
}
</style>
