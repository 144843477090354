<template>
  <div class="stat_details">
    <span>{{ label }}</span>
    <p>
      {{ digit }} <span>{{ fiatCurrency }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "StatDetail",

  props: {
    label: {
      type: String,
    },
    digit: {
      type: String,
    },
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
    };
  },
};
</script>

<style src="./index.css" scoped></style>
