<template>
  <div class="wrapper">
    <div class="page_container">
      <BackComponent :title="$t('changePin')" />
    </div>

    <section class="page_container">
      <div class="form-wrapper">
        <form id="login-form">
          <div>
            <TextInput
              type="password"
              v-model="password"
              :placeholder="$t('currentPin')"
              :inputId="'password'"
              :inputClass="'modify-input'"
              maxLength="4"
            />
          </div>
          <div>
            <TextInput
              type="password"
              v-model="newPassword"
              :placeholder="$t('newPin')"
              :inputId="'newPassword'"
              :inputClass="'modify-input'"
              maxLength="4"
            />
          </div>
          <div>
            <TextInput
              type="password"
              v-model="confirmPassword"
              :placeholder="$t('confirmPin')"
              :inputId="'confirmPassword'"
              :inputClass="'modify-input'"
              maxLength="4"
            />
            <router-link to="/forgot-pin">
              <div class="disclaimer" style="padding: 4px 0 10px 0">
                <strong>{{ $t("forgotPin") }}</strong>
              </div>
            </router-link>
          </div>
          <div
            class="disclaimer"
            style="padding: 4px 0 10px 0; margin-bottom: 30px"
          >
            <template v-if="!passwordsMatch">
              <p class="confirm_pass_error" style="color: #dd4646">
                {{ $t("passwordsDoNotMatch") }}
              </p>
            </template>
          </div>
        </form>
      </div>

      <ChopbetButton
        :loading="loading"
        :disabled="
          !(password.length > 3) || !passwordsMatch || !confirmPassword
        "
        buttonClass=""
        variant="Variant"
        @click="handleChangePassword"
        ><span style="text-transform: uppercase">{{ $t("save") }}</span>
      </ChopbetButton>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TextInput from "../../components/ui/TextInput.vue";
import ChopbetButton from "../../components/ui/ChopbetButton.vue";
import BackComponent from "../../components/ui/BackComponent.vue";
import identity from "../../services/identity";

export default {
  name: "ChangePin",
  components: {
    TextInput,
    ChopbetButton,
    BackComponent,
  },
  comments: {},
  data: function () {
    return {
      newPassword: "",
      password: "",
      confirmPassword: "",
      error: [],
      warning: [],
      success: [],
      spinner: false,
      type: "password",
      type2: "password",
      type3: "password",
      btnText: "/img/icons/eye.svg",
      btnText2: "/img/other/eye.svg",
      btnText3: "/img/other/eye.svg",
      myProfile: this.getProfile(),
      loading: false,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },

    passwordsMatch() {
      return this.newPassword === this.confirmPassword || !this.confirmPassword;
    },
    ...mapState(["referralcode"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        document.title = "My Account";
        document.description = "Manage Chopbet account";
      },
    },
  },

  methods: {
    async handleChangePassword() {
      const payload = {
        new_pin: Number(this.newPassword),
        old_pin: Number(this.password),
      };
      this.loading = true;
      try {
        var path = "/pin";
        const res = await identity.patch(path, payload, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        if (res.status === 200) {
          this.setSuccess(`${this.$t("pinCreatedSuccessfully")}`);
          this.$router.push("/my-account");
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          const errorCode = parseInt(err.response.data.status);
          if (errorCode === 401 || errorCode === 428) {
            this.setError(err.response.data.message);
            this.logout();
            return;
          } else {
            this.setError(err.response.data.error_message);
          }
        } else if (err.request) {
          this.setError(
            `${this.$t("networkError")}`,
            `${this.$t("checkYourNetwork")}`
          );
        } else {
          // console.log(JSON.stringify(err));
        }
      }
    },
  },
  mounted: function () {
    var vm = this;

    vm.myProfile = vm.getProfile();

    if (!vm.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }

    this.$store.dispatch("setCurrentPage", "change-password");
  },
};
</script>

<style scoped>
#login-form > div {
  margin: 0.8rem 0;
  /* outline: 1px solid red; */
}
</style>
