<template>
  <div class="page_container">
    <div class="no_bet_slip">
      <NoBetSlipIcon />
      <p>{{ $t("emptyBetSlip") }}</p>
    </div>

    <div>
      <h3>{{ $t("bookingCode") }}:</h3>
      <div class="code_input">
        <div class="input_wrapper">
          <input
            type="text"
            placeholder="Search"
            aria-label="Search"
            name="code"
            v-model="code"
          />
        </div>
        <ChopbetButton
          :loading="loading"
          @click="handleLoadBet"
          :disabled="disabledLoad"
          variant="outline"
          >{{ $t("load") }}</ChopbetButton
        >
      </div>
      <ChopbetButton variant="primary" @click="handleLearnMore">{{
        $t("learnHowToPlaceBets")
      }}</ChopbetButton>
    </div>

    <ChopbetSlideUp :isOpen="isSlideUpOpen" @closeSlideup="closeSlideUp">
      <div class="learn_more page_container">
        <div class="head">
          <span>{{ $t("howToPlaceBets") }}</span>
          <div class="close" @click="closeSlideUp">
            <CancelIcon />
          </div>
        </div>
        <Accordion :title="$t('SportsBettingExplained')">
          <p v-html="formatText($t('sportsBettingDefinition'))"></p>
          <p v-html="formatText($t('bettingOptions'))"></p>
        </Accordion>
        <Accordion :title="$t('resultBetting')">
          <p v-html="formatText($t('resultBettingDefinition'))"></p>
          <p v-html="formatText($t('cupMatchTip'))"></p>
        </Accordion>
        <Accordion :title="$t('oddsAndPayouts')">
          <p v-html="formatText($t('oddsAndPayoutsExplanation'))"></p>
        </Accordion>
        <Accordion :title="$t('popularMarkets')">
          <p v-html="formatText($t('somePopularmarket'))"></p>
          <p v-html="formatText($t('overUnderExplanation'))"></p>
          <div class="accordion_detail">
            <div class="accordion_head">
              <p v-html="formatText($t('bothTeamsToScore'))"></p>
            </div>
            <p v-html="formatText($t('bothTeamsToScoreExplanation'))"></p>
          </div>
          <div class="accordion_detail">
            <div class="accordion_head">
              <p v-html="formatText($t('correctScore'))"></p>
            </div>
            <p v-html="formatText($t('correctScoreExplanation'))"></p>
          </div>
          <div class="accordion_detail">
            <div class="accordion_head">
              <p v-html="formatText($t('goalScorer'))"></p>
            </div>
            <p v-html="formatText($t('goalScorerExplanation'))"></p>
          </div>
          <div class="accordion_detail">
            <div class="accordion_head">
              <p v-html="formatText($t('threeWayHandicap'))"></p>
            </div>
            <p v-html="formatText($t('threeWayHandicapExplanation'))"></p>
          </div>
        </Accordion>
      </div>
    </ChopbetSlideUp>
  </div>
</template>

<script>
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import NoBetSlipIcon from "../../../components/icons/NoBetSlipIcon.vue";
import { loadBetSlip } from "../../../actions/fixtures";
import ChopbetSlideUp from "../../../components/ui/ChopbetSlideUp.vue";
import CancelIcon from "../../../components/icons/CancelIcon.vue";
import Accordion from "../../../components/ui/Accordion.vue";
export default {
  name: "Booking",
  components: {
    ChopbetButton,
    NoBetSlipIcon,
    ChopbetSlideUp,
    CancelIcon,
    Accordion,
  },
  data() {
    return {
      code: "",
      booking_code: "",
      currency: process.env.VUE_APP_CURRENCY,
      amount: "",
      betslip_count: 0,
      odds: 0,
      payout: 0,
      disabled: true,
      loading: false,
      acceptOdds: false,
      useFreeBets: false,
      isSlideUpOpen: false,
    };
  },
  props: {
    hasAtLeastOneFieldInPicks: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const params = this.$route.params.id;
    if (params) {
      this.code = params;
      this.handleLoadBet();
    }
  },
  computed: {
    disabledLoad() {
      const val = this.code.length < 1;
      return val;
    },
  },
  watch: {
    isSlideUpOpen(newVal) {
      if (newVal) {
        this.isSlideUpOpen = true;
      }
    },
  },

  methods: {
    formatText(text) {
      return text && text.replace(/\/n/g, "<br>");
    },
    async handleLoadBet() {
      const vm = this;
      try {
        vm.loading = true;
        const res = await loadBetSlip({
          booking_code: vm.code,
          lang: this.$i18n.locale,
          apiKey: this.getAuth(),
        });
        vm.loading = false;

        if (res.error) {
          vm.setError(res.error);
          return;
        }
        var bookings = res;
        if (bookings) {
          let bSlip = this.getObject("bslip");
          if (!Array.isArray(bSlip)) {
            bSlip = [];
          }
          bookings?.forEach((booking) => {
            const payload = {
              sport_id: booking?.sport_id,
              match_id: booking?.match_id,
              market_name: booking?.market_name,
              market_id: booking?.market_id,
              home_team: booking?.event?.split(" vs. ")[0],
              away_team: booking?.event?.split(" vs. ")[1],
              producer_id: booking?.producer_id,
              picks: {
                ...booking,
                competitor_1: booking?.event?.split(" vs. ")[0],
                competitor_2: booking?.event?.split(" vs. ")[1],
              },
            };
            const { picks, match_id, market_id } = payload;
            picks.id = this.getOddID(
              match_id,
              market_id,
              picks.outcome_id,
              picks.specifier,
              "odd"
            );
            picks.id2 = this.getOddID(
              match_id,
              market_id,
              picks.outcome_id,
              picks.specifier,
              "boosted"
            );
            let isunselect = false;
            bSlip = bSlip.filter((v) => {
              if (v.id === picks.id) {
                isunselect = true;
              }
              return v.match_id !== match_id;
            });
            if (!isunselect) {
              bSlip.push(picks);
            }
          });
          if (bSlip.length > 29) {
            this.setError("Error", "Maximum number of games reached");
            return;
          }
          const bt = bSlip.filter((v) => v !== undefined && v);
          this.saveObject("bslip", bt);
          this.autoRefreshUI("removePick");
        }
      } catch (error) {
        vm.setError(error.message);
      } finally {
        vm.loading = false;
      }
    },
    closeSlideUp() {
      this.isSlideUpOpen = false;
    },
    handleLearnMore() {
      this.isSlideUpOpen = true;
    },
  },
};
</script>

<style scoped>
.close {
  cursor: pointer;
}

.no_bet_slip {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-top: 12px;
}

.no_bet_slip p {
  color: var(--text-color);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.code_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 8px;
}

h3 {
  color: var(--Light-Mode-Base-Base-900, #0e0d0d);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.12px;
}
.input_wrapper {
  width: 70%;
}

.input_wrapper input {
  height: 54px;
  margin-bottom: -4px;
}
.code_input button {
  width: 28%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.learn_more {
  padding: 0;
  margin: 16px 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 6rem;
  gap: 8px;
  span {
    color: var(--text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.14px;
    /* margin-bottom: 16px; */
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  /* padding-bottom: 16px; */
  p {
    /* color: var(--Light-Mode-Base-Base-900, #0e0d0d); */
    color: var(--text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
  }
}
/* 
.accordion {
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--box-bg);


  margin-bottom: 8px;
}
.accordion_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;

  background: var(--box-bg);

  border-radius: 5px;
  width: 100%;
  p {

    color: var(--text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    margin: 0;
  }
} */

.accordion_detail p {
  font-size: 14px;
  color: var(--sub-text-color);
}
.accordion_head {
  padding: 12px 0px;
}
</style>
