import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=4f4b62be&scoped=true"
import script from "./Accordion.vue?vue&type=script&lang=js"
export * from "./Accordion.vue?vue&type=script&lang=js"
import style0 from "./Accordion.vue?vue&type=style&index=0&id=4f4b62be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f4b62be",
  null
  
)

export default component.exports