<template>
  <div v-if="isOpen" class="modal_overlay" @click="handleClose">
    <div
      ref="modalRef"
      :class="[
        'modal_content',
        maxWidth ? 'max_modal_content' : '',
        fadeInClass,
      ]"
      :style="customStyles"
      @click.stop
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
    },
    maxWidth: {
      type: String,
      default: "",
    },
    customStyles: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fadeInClass: "", // Class to handle fade-in animation
    };
  },
  watch: {
    isOpen(open) {
      this.toggleBodyOverflow(open);
      this.toggleFadeAnimation(open);
    },
  },
  methods: {
    toggleBodyOverflow(open) {
      document.body.style.position = open ? "fixed" : "";
    },
    toggleFadeAnimation(open) {
      if (open) {
        this.fadeInClass = "fade_in";
      } else {
        this.fadeInClass = "";
      }
    },
    handleClose() {
      this.onClose && this.onClose();
    },
  },
  beforeDestroy() {
    document.body.style.position = "";
  },
};
</script>

<style scoped>
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal_content {
  border-radius: 16px;
  min-width: 300px;
  overflow: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  padding: 0.3rem;
  outline: none;
  background-color: var(--background-color);
  color: var(--text-color);
}

.max_modal_content {
  width: 600px;
}

@media (max-width: 760px) {
  .max_modal_content {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .max_modal_content {
    width: 90%;
  }
}

/* Define fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply fade-in animation to modal content */
.fade_in {
  animation: fadeIn 0.2s ease-in forwards;
}
</style>
