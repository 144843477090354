<template>
  <button
    :type="type"
    :class="buttonClass"
    :aria-label="ariaLabel"
    @click="handleClick"
    :disabled="disabled"
    :style="{
      height:
        size === 'small'
          ? '40px !important'
          : casino
          ? '24px!important'
          : '54px!important',
    }"
  >
    <div v-if="loading">
      <LoadingSpinner
        size="30"
        :color="variant === 'primary' ? '#fff' : '#000'"
      />
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "ChopbetButton",
  components: {
    LoadingSpinner: () => import("./LoadingSpinner.vue"),
  },
  props: {
    type: {
      type: String,
      default: "button",
    },
    variant: {
      type: String,
      default: "primary",
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "normal",
    },
    casino: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick(event) {
      if (!this.loading) {
        this.$emit("click", event);
      }
    },
  },
  computed: {
    buttonClass() {
      return ["btn", `btn-${this.variant}`];
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
  border-radius: 4px;
  background: var(--slide-active);
  /* font-family: "Game Station"; */
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  /* padding: 22px 0; */
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* align-self: stretch; */
  border: none;
  svg {
    width: 10px !important;
    height: 10px !important;
  }
}

/* button:disabled {
  background: #d1cfcf;
  color: #fff;
} */

button:disabled {
  background: #d1cfcf;
  color: #fff;
  cursor: not-allowed;
  border: none;
}

.btn-primary:hover {
  background: var(--redHover) !important;
}

.btn-primary:active {
  background: var(--redHover);
}

.btn-outline {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.btn-outline:active {
  background: #0000002e !important;
  background-color: rgba(0, 0, 0, 0.05);
}
.btn-outline:disabled:hover {
  background: #d1cfcf !important;
  background-color: none;
}
.btn-outline:hover {
  background: #0000002e !important;
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
