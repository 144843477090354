<template>
  <div>
    <div
      class="accordion text-dark"
      @click="toggleCollapse"
      :aria-expanded="isCollapsed"
    >
      <div class="accordion_head">
        <p>{{ title }}</p>
        <span v-html="isCollapsed ? arrowUpIcon : arrowDownIcon"></span>
      </div>
    </div>
    <div
      v-show="isCollapsed"
      class="accordion_body promo-content text-dark"
      :style="bodyStyle"
    >
      <div class="accordion_content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
.accordion_body {
  /* width: 80%; */
  background-color: var(--slide-fill);
  padding: 0 12px;
}
.accordion_content {
  border-top: dotted 1px #00000033;
  padding: 8px 0 16px;
}
p {
  margin: 0;
}

.head {
  margin-bottom: 8px;
}
.accordion_container {
  padding: 8px;
}
.accordion {
  padding: 8px;
}
.accordion_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 8px 0px; */
  /* background: var(--box-bg); */
  border-radius: 5px;
  width: 100%;
  p {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
}

.container_wrapper {
  background: var(--background-color);
  color: var(--help-sub-text);
}

h1,
h2 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: var(--text-color);
}

h2 {
  font-size: 1.5em;
}
strong {
  color: var(--text-color);
}
h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}

.selfcare-title {
  padding: 10px 0 !important;
}
h6 {
  font-size: 16px;
}

.collapse.show {
  display: block;
}
.collapse {
  display: none;
  transition: max-height 0.3s ease-in-out;
}

.accordion {
  background-color: var(--slide-fill);
  padding: 16px;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
</style>

<script>
export default {
  name: "Accordion",
  props: {
    title: {
      type: String,
      required: true,
    },
    defaultOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapsed: this.defaultOpen,
      arrowDownIcon: `
          <svg width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6 7.46L11.17 12.89a1.5 1.5 0 01-2.34 0L3.4 7.46" stroke="var(--sub-text-color)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
      arrowUpIcon: `
          <svg width="15" height="15" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.3999 12.543L8.83324 7.10966C9.4749 6.468 10.5249 6.468 11.1666 7.10966L16.5999 12.543"
              stroke="var(--sub-text-color)"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        `,
    };
  },
  computed: {},
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>
