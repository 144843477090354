import referral from "../services/referral";

export const fetchReferralCode = async (payloadData) => {
  try {
    const res = await referral.get("/referrals/code", {
      headers: {
        "api-key": payloadData.apiKey,
      },
    });
    return res.data;
  } catch (error) {
    let errorMessage = "An error occurred";

    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;

      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session on this device has expired";
        return;
      }
    }

    throw new Error(errorMessage);
  } finally {
    console.log("finally");
  }
};

export const initiateWithdrawal = async (payloadData) => {
  const res = await referral.post(
    "/wallets/withdraw",
    {
      amount: payloadData.amount,
    },
    {
      headers: {
        "api-key": payloadData.apiKey,
      },
    }
  );
  return res.data;
};

export const fetchBalance = async (payloadData) => {
  try {
    const res = await referral.get("/wallets/balance", {
      headers: {
        "api-key": payloadData.apiKey,
      },
    });
    return res.data;
  } catch (error) {
    let errorMessage = "An error occurred";

    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;

      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session on this device has expired";
        return;
      }
    }

    throw new Error(errorMessage);
  } finally {
    console.log("finally");
  }
};

export const fetchCommission = async (payloadData) => {
  try {
    const res = await referral.get("/referrals/commission-summary", {
      headers: {
        "api-key": payloadData.apiKey,
      },
    });
    return res.data;
  } catch (error) {
    let errorMessage = "An error occurred";

    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;

      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session on this device has expired";
        return;
      }
    }

    throw new Error(errorMessage);
  } finally {
    console.log("finally");
  }
};

export const fetchTransaction = async ({
  page = 1,
  per_page = 20,
  start = "",
  end = "",
  commission_type = "",
  sortDir = "DESC",
  type = 0,
  apiKey,
}) => {
  let queryParams = {
    page,
    sortDir,
    per_page,
  };

  if (start) {
    queryParams.start = start;
  }

  if (end) {
    queryParams.end = end;
  }

  if (type) {
    queryParams.type = type;
  }

  if (commission_type) {
    queryParams.commission_type = commission_type;
  }

  try {
    const res = await referral.get("/wallets/transactions", {
      headers: {
        "api-key": apiKey,
      },
      params: queryParams,
    });
    return res.data;
  } catch (error) {
    let errorMessage = "An error occurred";

    if (error.response) {
      const status = error.response.status;
      errorMessage = error.response.data.message;

      if ([401, 400, 428].includes(status)) {
        errorMessage = "Your session on this device has expired";
        return;
      }
    }

    throw new Error(errorMessage);
  } finally {
    console.log("finally");
  }
};

// export const fetchWithdrawal = async (payloadData) => {
//   try {
//     const res = await referral.get("/wallets/transactions", {
//       headers: {
//         "api-key": payloadData.apiKey,
//       },
//     });
//     return res.data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     // if (parseInt(err.response.status) === 401) {
//     //   this.setError(message);
//     //   vm.logout();
//     //   return;
//     // }
//   } finally {
//     console.log("finally");
//   }
// };
